import VueI18n from 'vue-i18n'
import Vue from 'vue'

import en from 'element-ui/lib/locale/lang/en'
import zh from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
import enLocale from './lang/en.json'
import zhLocale from './lang/zh.json'

Vue.use(VueI18n)

// 引入本地包
const messages = {
  'en': Object.assign(en, enLocale),
  "zh": Object.assign(zh, zhLocale)
}
//从localStorage中拿到用户的语言选择，如果没有，那默认中文。
/*const i18n = new VueI18n({
    locale: localStorage.lang || 'zh',
    messages,
})*/
// 创建国际化实例
const i18n = new VueI18n({
  silentTranslationWarn: true,// 设置为true 会屏蔽翻译在控制台报出的警告
  //locale: window.sessionStorage.getItem('lang') || 'zh',
  locale: localStorage.getItem('locale') || 'en',
  messages : messages
})

locale.i18n((key, value) => i18n.t(key, value))

export default i18n