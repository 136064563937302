<template>
  
      <el-row type="flex" >
          <el-col :md="24">
              <el-row type="flex">
                  <el-col :md="24" >
                      <div class="top-menu">
                          <div style="padding: 10px 0px;">
                              <img height="50px" src="../../assets/images/top-logo.png"/>
                          </div>
                        <b-navbar class="navbar-default"  toggleable="lg" type="light" variant="light">
                              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                              <b-collapse id="nav-collapse" is-nav>
                                <b-navbar-nav>
                                    <b-nav-item href="/" class="active">{{ $t('home.navBar.home')}}</b-nav-item>
                                    <b-nav-item href="/about">{{ $t('home.navBar.about')}}</b-nav-item>
                                    <b-nav-item href="/service">{{ $t('home.navBar.service')}}</b-nav-item>
                                    <b-nav-item href="/news">{{ $t('home.navBar.news')}}</b-nav-item>
                                    <b-nav-item href="/join">{{ $t('home.navBar.join')}}</b-nav-item>
                                </b-navbar-nav>
                                <b-navbar-nav class="ml-auto">
                                  <b-nav-item-dropdown :text="$t('home.navBar.lang')" align="left" >
                                    <b-dropdown-item @click="Ch">中文</b-dropdown-item>
                                    <b-dropdown-item @click="En">English</b-dropdown-item>
                                  </b-nav-item-dropdown>
                                </b-navbar-nav>
                              </b-collapse>
                            </b-navbar>
                       </div>
                  </el-col>
              </el-row>
          </el-col>
      </el-row>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1'
    };
  },
  mounted() {
     
  },
  methods: {
    Ch(){
        this.$i18n.locale = 'zh'
        localStorage.setItem('locale','zh')
        this.redirect()
    },
    En(){
        this.$i18n.locale = 'en'
        localStorage.setItem('locale','en')
        this.redirect()
    },
    handleCommand(command, commandValue) {
    },
    redirect(){
        if (this.$route.path === '/') {
            location.reload()
            return
        } else {
            this.$router.replace('/')
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.logo {
  width: 50px; /* 根据需要调整 logo 的宽度 */
}
.top-menu{
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    width: 100%;
    padding:20px 70px;
    @media only screen and (max-width: 992px){
        padding:0 5px;
    }
    .nav-item{
        padding: 0 10px;
    }
    font-size: 16px;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}
</style>