<template>
  <div id="app">
    <TopNav />
    
    <router-view/>
    <btmBar />
  </div>
</template>
<script>
    import TopNav from '@/components/topNav/topNav.vue'
    const btmBar = () => import('@/components/btmBar/btmBar.vue')
    export default{
        components:{
            TopNav,btmBar
        },
        watch: {
            // $route (to) {
            //   document.getElementsByClassName('app')[0].scrollTop = 0 // 设置滚动条位置;
            // }
        }
    }
</script>
<style lang="scss">
 @import "./assets/font/iconfont.css";
  * {
    font-family: "Ding";
  }
#app {
  font-family: "Ding" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 20px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.ql-size-22px{
    font-size: 22px;
}
.ql-size-24px{
    font-size: 24px;
}
.ql-size-18px{
    font-size: 18px;
}
.ql-size-16px{
    font-size: 16px;
}
.ql-size-14px{
    font-size: 14px;
}
.ql-size-10px{
    font-size: 10px;
}
.ql-size-36px{
    font-size: 36px;
}
.ql-size-34px{
    font-size: 34px;
}
.ql-size-32px{
    font-size: 32px;
}
.ql-size-30px{
    font-size: 30px;
}
.ql-size-28px{
    font-size: 28px;
}
.ql-size-26px{
    font-size: 26px;
}

</style>
