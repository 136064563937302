<template>
    <div >
        <section style="position: relative;">
            <div class="swiper-container ">
                <div class="swiper-wrapper ">
                    <div class="swiper-slide my-swiper" style="height: 100vh;"
                    :style="{backgroundImage:'url('+img.imgUrl+')'}"
                     v-for="(img,index) in bannerList" :key="index">
                      <!-- <span>专注 · 价值 · 服务</span> -->
                    </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
                
            </div>
            <el-row type="flex" justify="center">
                <div class="more-page">
                    <p class="iconfont mh-weibiaoti-1-18"></p>
                    <p class="iconfont mh-weibiaoti-1-19 one"></p>
                    <p class="iconfont mh-weibiaoti-1-19 two"></p>
                </div>
            </el-row>
        </section>
        <section class="section">
            <el-row type="flex" justify="center" class="padding-fullPage">
                <el-col :md="22">
                    <el-row type="flex" style="align-items: center;">
                        <el-col :md="12" :xs="24" :sm="24" class="about-left">
                             <el-row class="hidden-sm-and-down">
                                 <el-col :md="24" :xs="24" >
                                     <div class="title-opt  " data-wow-delay=".1s" data-wow-duration="1s" >ABOUT US</div>
                                     <div class="hct">{{ $t("home.cpninfo.title") }}</div>
                                 </el-col>
                             </el-row>
                             <el-row type="flex" justify="center" class="h-menu-panel hidden-md-and-up" >
                                 <el-col :md="20" class="h-menu-title">
                                     <div class="news-sub">ABOUT US</div>
                                     <div class="news-title" >{{ $t("home.cpninfo.title") }}</div>
                                 </el-col>
                             </el-row>
                             <el-row class="hcc " >
                                 <el-row class="content" style=""> <div> {{ $t("home.cpninfo.content") }}</div></el-row>
                                 <el-row class="c-data hidden-sm-and-down" >
                                     <el-col :md="24">
                                         <el-col :md="6" :xs="12" class="d-dta-item" v-for="(item,index) in $t('home.cpninfo.aboutUs')" :key="index">
                                              <div class="data" :data-info="item.dataTag" :data-to="item.Bdata">{{item.Bdata}}</div>
                                              <span style="text-align: center;">{{item.msg}}</span>
                                         </el-col>
                                     </el-col>
                                 </el-row>
                                 <el-col :md="6" :xs="12"  class="dc-dta-item hidden-md-and-up" v-for="(item,index) in $t('home.cpninfo.aboutUs')" :key="index">
                                     <div class="data" :data-info="item.dataTag" :data-to="item.Bdata">{{item.Bdata}}</div>
                                     <span style="text-align: center;font-weight: bold;">{{item.msg}}</span>
                                 </el-col>
                             </el-row>
                             <!-- <el-col class="more"><div>{{ $t("home.cpninfo.more")}} ></div> </el-col> -->
                        </el-col>
                        <el-col :md="12" class="hidden-sm-and-down">
                            <div class="el-col-12 el-col-lg-12" style="width: 100%;">
                                <div class="tow-right"></div>
                            </div>
                        </el-col>
                    </el-row>
                    
                </el-col>
            </el-row>
        </section>
        <section class="services hidden-sm-and-down" :style="{backgroundImage:'url('+servicesBg+')'}" >
            <div class="bar-msg ">
                <span class="title-opt ">SERVICE</span>
                <span class="title">{{$t('home.service.title')}}</span>
                <!-- <span style="font-size: 20px;margin-top: 100px;">{{$t('home.service.more')}} ></span> -->
            </div>
           <div class="bar ">
               <div @mouseover="onHover(index)" :style="{backgroundColor: idx === index ? '#BA4D48':''}" class="bar-item"
                    v-for="(item,index) in $t('home.service.swiper') " :data-tag="item.tag" :key="index">
                   <div class="iconfont  pro-icon" :class="item.icon" style="font-size: 30px;"></div>
                   <div>{{item.title}}</div>
               </div>
           </div>
        </section>
        <section>
        <el-row style="background-color: #BA4D48;padding: 0px 0 30px" class="hidden-md-and-up">
            <el-col :md="24">
                <el-row type="flex" justify="center" class="h-menu-panel" >
                    <el-col :md="20" class="h-menu-title">
                        <div class="news-sub" style="color: #fff;">SERVICE</div>
                        <div class="news-title" style="color: #fff;">{{$t('home.service.title')}}</div>
                    </el-col>
                </el-row>
                <el-row type="flex" justify="center">
                    <el-col :xs="22">
                        <el-col :xs="24" style="text-align: center;font-size: 14px;background-color: #fff;padding: 10px;">
                            <span class="iconfont mh-weibiaoti-1-18"></span>
                            <span style="font-weight: bold;">益生菌</span>
                        </el-col> 
                        <el-col :xs="24" style="text-align: center;background-color: #fff;margin-top: 10px;">
                            <img :src="servicesBgs[0]" style="width: 100%;"/>
                        </el-col>
                        <el-col :xs="24" style="text-align: center;margin-top: 30px;">
                            <el-row type="flex" :gutter="5" >
                                <el-col :xs="8" v-for="(item,index) in $t('home.products')" @click.native="selectPro(item.id)" :key="index">
                                    <div style="padding: 8px 0;" 
                                    :style="{background:proIdx==item.id ? '#5b917a':'#FFF',color:proIdx==item.id ?'#fff':'' }">
                                        <span class="iconfont" :class="item.icon"></span>
                                        <span>{{item.title}}</span>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-col :xs="24" style="text-align: center;background-color: #fff;margin-top: 5px;">
                                <img :src="servicesBgs[proIdx]" style="width: 100%;"/>
                            </el-col>
                        </el-col>
                        
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        </section>
        
        
        <section class="three-view" >
           <el-row class="hidden-sm-and-down" justify="center" type="flex">
               <el-col :span="8"  class="new-center">
                   <div class="three-left">
                       <div class="left-title ">
                           <div class="title-opt">{{ $t('home.news.backTitle') }}</div>
                           <div class="sub-title">{{ $t('home.news.title') }}</div>
                       </div>
                       <div class="titile-menu">
                           <div @click="selectPro(index)"  v-for="(item,index) in $t('home.news.menu')" :key="index" class="hover-hand">
                                <div style="height: 40px;" > {{item.title}}</div> 
                           </div>
                       </div>
                   </div>
                </el-col>   
                <el-col  :span="14"  >
                    <el-row class="news" type="flex" justify="space-between" :gutter="10">
                            <el-col :md="8"  @click.native="redirect(item.href)" 
                                     v-for="(item,index) in currentNews" :key="index"
                                    :span="8" >
                                <div class="detail">
                                <div class="news-item">
                                   <div class="news-img" :style="{backgroundImage:'url('+item.img+')'}"></div>   
                                </div>
                                
                                <div class="msg">
                                    <div class="title">{{item.title}}</div>
                                    <div class="content">{{item.content}}</div>
                                </div>
                                </div>
                            </el-col>
                    </el-row>
                </el-col>  
           </el-row>
           <el-row class="hidden-md-and-up">
               <el-col :md="24">
                   <el-row type="flex" justify="center" class="h-menu-panel" >
                       <el-col :md="20" class="h-menu-title">
                           <div class="news-sub" >{{ $t('home.news.backTitle') }}</div>
                           <div class="news-title" >{{ $t('home.news.title') }}</div>
                       </el-col>
                   </el-row>
                   <el-row  justify="center" type="flex" @click.native="redirect(item.href)" class="dynamic" v-for="(item,index) in currentNews" :key="index">
                       <el-col :xs="22" class="panel">
                           <div class="dumpImg" :style="{backgroundImage:'url('+item.img+')'}"></div>
                           <!-- <div class="dumpImg"></div> -->
                           <div class="title-sub"> 
                                <!-- <span class="iconfont mh-rili" ></span> -->
                                <span style="font-size: 16px;"> {{item.time}}</span> 
                           </div>
                           <div class="content">
                               <div>{{item.title}}</div>
                               <span>{{item.content}}</span>
                           </div>
                       </el-col>
                   </el-row>
                   
               </el-col>
           </el-row>
        </section>
    </div>
</template>
<script>
    import Swiper from 'swiper'
    import { WOW } from 'wowjs'
    export default{
        data(){
            return{
                options: {
                        licenseKey: 'YOUR_KEY_HEERE',
                        menu: '#menu',
                        anchors: ['page1', 'page2', 'page3'],
                        sectionsColor: ['#41b883', '#ff5f45', '#0798ec'],
                      },
                scanCode:0,
                servicesBgs:
                [
                    require('@/assets/images/home/baiao.jpg'),
                    require('@/assets/images/home/fh.jpg'),
                    require('@/assets/images/home/ser.jpg'),
                    require('@/assets/images/home/aed.jpg')
                    
                ],
                proIdx:1,
                servicesBg: require('@/assets/images/home/baiao.jpg'),
                idx:0,
                scanimg:require('@/assets/images/home/bottom-logo.png'),
                code:require('@/assets/images/home/bottom-code.jpg'),
                bannerList:[
                    {imgUrl:'https://scan-code.oss-cn-hangzhou.aliyuncs.com/menhu/home/swiper1.jpg'},
                    {imgUrl:'https://scan-code.oss-cn-hangzhou.aliyuncs.com/menhu/home/swiper1.jpg'}
                ],
                currentNews:{}
            }
        },
        mounted() {
            new Swiper(".swiper-container", {
                loop: true,
                onwheel:true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                },                    
            });
            const wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: true,
                live:true
                })
            wow.init()
        },
        created() {
            this.currentNews = this.currentNews = this.$t('newscenter.cpnNews.cpn').slice(0, 3)
        },
        computed:{
        },
        methods:{
           selectPro(idx){
               this.proIdx = idx
               switch (idx){
                    case 0:
                        this.currentNews = this.$t('newscenter.cpnNews.cpn').slice(0, 3)
                       break;
                    case 1:
                        this.currentNews = this.$t('newscenter.cpnNews.emp').slice(0, 3)
                       break;
                    case 2:
                         this.currentNews = this.$t('newscenter.hmlNews.baiao').slice(0, 3)
                        break;
                    case 3:
                         this.currentNews = this.$t('newscenter.hmlNews.hml').slice(0, 3)
                        break;
               }
               
              // this.currentNews = this.news[idx]
              
           },
           redirect(href){
               window.open(href,"_blank")
           },
            onHover(idx){
                this.idx = idx
                this.servicesBg = this.servicesBgs[idx]
            },
        },
    }
</script>
<style>
</style>
<style lang="scss" scoped>
    @import '../assets/mh.scss';
    .hover-hand{
        transition: all 0.8s ease-in-out;
        &:hover{
            letter-spacing: 4px;
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
    .dynamic{
        &+.dynamic{
            margin-top: 20px;
        }
        .el-col{
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            overflow: hidden;
            .dumpImg{
                height: 250px;
                max-height: 250px;
                width: 100%;
                background-size: cover;
                background-position: center;
                background-image:url('../assets/images/2.jpg');
            }
            .iconfont{
                font-size: 20px;
                color: #BA4D48;
            }
            .title-sub{
                padding: 10px 15px 0;
                font-weight: bold;
            }
            
            .content{
                line-height: 22px;
                padding: 0px 15px 15px;
                div{
                    padding: 5px 0 10px;
                    font-size: 18px;
                    font-weight: bold;
                    
                }
                span{
                    font-size: 16px;
                    color: rgba(0, 0, 0, .6);
                    overflow: hidden;
                    /* height: 49px; */
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
    .section{
        @media only screen and (min-width:900px){
          height: 100vh;
          display: flex;
          align-items: center;
        }
    }
    .about-left{
        
        @media only screen and (max-width:992px){
           padding:20px;
           .more{
                margin-top: 20px;
                font-size: $h-font-16;
           }
        }
        padding: 0 50px 0 0px;
        .more{
            @media only screen and (max-width:992px){
               display: flex;
               margin: 40px 0;
               div{
                   padding: 5px 10px;
                   border: 1px solid;
               }
              
               display: flex;
               justify-content: center;
               
            }
            margin-top: 100px;
            font-size: $h-font-16;
        }
    }
    .hcc{
        font-size: $font-content;
        line-height: 24px;
        .content{
            padding: 40px 0;
            text-indent: 34px;line-height: 28px;
        }
        .dc-dta-item{
            text-align: center;
            padding: 20px;
            .data{
                @media only screen and (max-width:900px){
                   font-size: $h-shadow-sub-mini;
                }
                font-size: $h-shadow-sub;
                display: flex;
                justify-content: center;
                align-items: baseline;
                color: #BA4D48;
                font-weight: bold;
                animation: count-up 2s ease-in-out;
                &:after{
                    position: relative;
                    content: attr(data-info);
                    margin-left: 5px;
                    font-size: $h-font-16;
                    color: #BA4D48;
                    
                }
            }
            
            &+.d-dta-item{
                position: relative;
                &:after{
                    position: absolute;
                    content: '';
                    top: 5px;
                    bottom: 5px;
                }
            }
            
        }
    }
    .hct{
        @media only screen and (max-width:900px){
           text-align: center;
        }
        font-size: $h-shadow-sub;
    }
    .swiper-slide {
    
        overflow: auto;
    }
    a{
        text-decoration: none;
        color: #000000;
    }
    .swiper-button-next{
        padding: 30px;
        border-radius: 50%;
    }
    .c-data{
        display: flex;
        flex-direction: row;
        padding-top: 50px;
        justify-content: between;
        
        .d-dta-item{
            width: 25%;
            display: flex;
            flex-direction: column;
            .data{
                @media only screen and (max-width:400px){
                   font-size: 30px;
                }
                @media only screen and (min-width:900px){
                   font-size: $h-tag-font;
                }
                display: flex;
                justify-content: center;
                align-items: baseline;
                color: #BA4D48;
                font-weight: bold;
                animation: count-up 2s ease-in-out;
                &:after{
                    position: relative;
                    content: attr(data-info);
                    margin-left: 5px;
                    font-size: $h-font-16;
                    color: #aba8a8;
                }
            }
            
            &+.d-dta-item{
                position: relative;
                &:after{
                    position: absolute;
                    content: '';
                    top: 5px;
                    border: 0.5px solid #ebe6e6;
                    bottom: 5px;
                }
            }
        }
    }
    @keyframes count-up {
        from{
            content: 0;
        }
        to{
            content: attr(data-to);
        }
    }
    .tow-right{
        background-image:url('../assets/images/3.jpg');
        background-size: cover;
        
        @media only screen and (min-width:900px){
           height: 500px;
        }
        @media only screen and (max-width:900px){
           height: 300px;
        }
    }
    .my-swiper{
        height: 100vh;
        width: 100vw;
        background-size: cover;
        background-position: center;
        display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3.5em;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 1px 1px 8px #000000;
    }
    .services{
        height: 100vh;
        position: relative;
        //background-image: url('../assets/images/pro.jpg');
        background-size: cover;
        transition: all .5s ease-in-out;  
        .bar-msg{
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 100px;
            color: #FFF;
            .title{
                font-size: $h-shadow-sub;
            }
        }
        .bar{
            position: absolute;
            bottom: 0px;
            height: 150px;
            width: 100%;
            background-color: rgba(91, 91, 91, 0.7);
            display: flex;
            justify-content: center;
            
            .bar-item{
                display: flex;
                justify-content: center;
                position: relative;
                color: #FFFFFF;
                &:nth-child(1){
                 border-left: 0.5px solid #aba8a8;
                 }
                border-right: 0.5px solid #aba8a8;
                width: 25%;
                height: 150px;
                text-align: center;
                align-items: center;
                // transition: background-color 0.5s ease;
                &:hover {
                    background-color: #BA4D48;
                    &:before{
                        content: attr(data-tag);
                        position: absolute;
                        /* height: 47px; */
                        left: 0;
                        /* display: flex; */
                        top: -30px;
                        left: 0;
                        z-index: 99;
                        background-color: #BA4D48;
                        /* justify-content: center; */
                        /* align-items: center; */
                        padding: 5px 30px 5px;
                        border-top-right-radius: 20px;
                        border-top-left-radius: 10px;
                    }
                }
            }
        }
    }
    .three-view{
        @media screen and (min-width:800px) {
            height: 100vh;
            display: flex;
            align-items: center
        }
        .three-left{
            
            position: relative;
            .left-title{
                .title{
                    font-size: $h-shadow;
                    font-weight: bold;
                    opacity: .2;
                }
                .sub-title{
                    font-size: $h-shadow-sub;
                }
            }
            .titile-menu{
                padding: 30px 0;
                display: flex;
                flex-direction: column;
                font-size: $h-nomal;
            }
        }
        .three-right{
            display: flex;
            flex-direction: row;
            
        }
    }
    .new-center{
        position: relative;
        z-index: 2;
        &:before{
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: .3;
            bottom: -100px;
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: left bottom;
            background-image: url('../assets/images/abc.png');
        }
    }
    
    .news{
        
        .detail{
            border-radius: 10px;
            overflow: hidden;
            border: 1px solid rgba(0, 0, 0, .1);
            box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
            transition: all 0.5s;
            .news-item{
                height: 320px;
                overflow: hidden;
            }
            &:hover{
                box-shadow: 4px 4px 4px rgba(0, 0, 0, .2);
                .news-img{
                    scale: 1.2;
                }
            }
            .news-img{
                z-index: 1;
                height: 100%;
                background-size: cover;
                background-position: center;
                transition: all 0.5s;
            }
            .msg{
                padding: 15px 10px;
                .title{
                    font-size: $news-title;
                    min-height: 30px;
                    height: 30px;
                    overflow: hidden;
                    /* height: 49px; */
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                .content{
                    min-height: 80px;
                    height: 80px;
                    padding: 15px 0px;
                    font-size: $news-content;
                    color: rgba(0, 0, 0, .6);
                    overflow: hidden;
                    /* height: 49px; */
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
            
        }
        
    }
    .btm-col{
        display: flex;
        flex-wrap: wrap;
    }
    .btm-info{
       .btm-icon{
           font-size: 18px;
           border: 3px solid;
           border-radius: 50%;
           padding: 5px;
           width: 38px;
           height: 38px;
           text-align: center;
       }
       color: #aba8a8;
       margin-top: 20px;
       margin-left: 50px;
       display: flex;
       flex-direction: row;
       align-items: center;
       .info{
           font-size: 14px;
           display: flex;
           flex-direction: column;
           padding-left: 20px;
       }
    }
    .pro-icon{
        margin-right: 10px;
    }
</style>